import { Popover } from 'antd'
import dayjs from 'dayjs'
import { useState, type ChangeEvent, useReducer } from 'react'
import { useTranslation } from 'react-i18next'

import Field from '@/components/Form/Field/Field'
import Form from '@/components/Form/Form'
import Button from '@/components/ui/Button/Button'
import Dialog from '@/components/ui/Dialog/Dialog'
import { notificationDanger, notificationSuccess } from '@/components/ui/Notification/Notification'
import Config from '@/config/config'
import { useUser } from '@/contexts/user/User.context'
import { Currency, OrderRefundReason, OrderPaymentStatus, OrderStatus } from '@/network/graphql/types.generated'
import { trackEvent } from '@/util/eventTracker'

import { useRefundOrderMutation } from '../../../operations.generated'

import type { Order } from '@/network/graphql/types.generated'

import './OrderCancellation.scss'

const { VITE_APP_LONG_TIMEOUT } = Config

type RefundOrderAfterShippedProps = {
  order: Order
  onSubmittedCallback: () => void
}

export const RefundOrderAfterShipped = ({ order, onSubmittedCallback }: RefundOrderAfterShippedProps) => {
  const [isOpen, toggleIsOpen] = useReducer((isOpen) => !isOpen, false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [reason, setReason] = useState<OrderRefundReason>(OrderRefundReason.ChangeOfMind)

  const { t } = useTranslation()
  const { user } = useUser()
  const [refundOrder] = useRefundOrderMutation()

  const sellerId = user?.id
  const isAlreadyRefunded = order.paymentStatus === OrderPaymentStatus.Success && order.status === OrderStatus.Refunded
  const refundIsAllowed = dayjs(order.createdAt).isAfter(dayjs().subtract(5, 'month'))

  const handleSubmit = () => {
    setIsLoading(true)
    refundOrder({
      variables: {
        input: {
          orderId: order.id,
          refundReason: reason,
        },
      },
      context: {
        timeout: VITE_APP_LONG_TIMEOUT ? parseInt(VITE_APP_LONG_TIMEOUT) : 45000,
      },
      onCompleted: () => {
        setIsLoading(false)
        notificationSuccess(t('ordersCancellationModalMessageSucces'))
        toggleIsOpen()
        if (onSubmittedCallback) onSubmittedCallback()
        trackEvent('ORDER_CANCEL_REQUEST', { sellerId })
      },
      onError: (error) => {
        setIsLoading(false)
        notificationDanger(error.message)
      },
    })
  }

  const handleOnChangeReason = (event: ChangeEvent<HTMLSelectElement>) => {
    if (assertValueIsValidReason(event.target.value)) {
      setReason(event.target.value)
    }
  }

  return (
    <div>
      <Popover content={t('Refund order')}>
        <Button
          className="cancel-order-button secondary"
          disabled={!refundIsAllowed}
          label={t('ordersSentListCancelOrderActionLabel')}
          tooltip={refundIsAllowed ? undefined : t('ordersSentListForbiddenCancelOrderActionTooltip')}
          tooltipPosition="top right"
          onClick={toggleIsOpen}
        />
      </Popover>
      <Dialog
        className="order-cancellation"
        isOpen={isOpen}
        title={t('ordersCancellationModalTitle')}
        onClose={toggleIsOpen}
      >
        {!isAlreadyRefunded && order.amountInCurrencyCents && (
          <p className="cancellation-amount">
            {t('order-cancellation-amount-info')} {order.amountInCurrencyCents / 100}
            {order.currency === Currency.Eur ? '€' : '£'}
            <br />
            {t('order-cancellation-amount-info-shipping')}
          </p>
        )}

        <Form onSubmit={handleSubmit}>
          <Field
            label={t('ordersCancellationModalInputLabel')}
            name="reason"
            options={AVAILABLE_REASONS.map((reason) => ({ ...reason, label: t(reason.label) }))}
            type="select"
            required
            onChange={handleOnChangeReason}
          />
          <div className="cancellation-buttons">
            <Button className="secondary" disabled={isLoading} label={t('commonCancel')} onClick={toggleIsOpen} />
            <Button
              className="primary"
              disabled={isLoading || !reason}
              isLoading={isLoading}
              label={t('commonValidate')}
              onClick={handleSubmit}
            />
          </div>
        </Form>
      </Dialog>
    </div>
  )
}

const AVAILABLE_REASONS: { value: OrderRefundReason; label: string }[] = [
  {
    label: 'commonRefundReasonChangeOfMind',
    value: OrderRefundReason.ChangeOfMind,
  },
  {
    label: 'commonRefundReasonDamagedProduct',
    value: OrderRefundReason.DamagedProduct,
  },
  {
    label: 'commonRefundReasonMissingProduct',
    value: OrderRefundReason.MissingProduct,
  },
  {
    label: 'commonRefundReasonLostParcel',
    value: OrderRefundReason.LostParcel,
  },
  {
    label: 'commonRefundReasonDelight',
    value: OrderRefundReason.Delight,
  },
  {
    label: 'commonRefundReasonOthers',
    value: OrderRefundReason.Others,
  },
]

const assertValueIsValidReason = (value: any): value is OrderRefundReason => {
  return Object.values(OrderRefundReason).includes(value)
}
