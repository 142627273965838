import { TimePicker, DatePicker } from 'antd'
import moment from 'moment'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { MaintenanceBanner } from '@/components/MaintenanceBanner.tsx/MaintenanceBanner'
import { isDayDisabled, getHoursSlots, getQuartersSlots } from '@/helpers/showSlotFinder'

import InputLabel from '../../InputLabel'

import type { Moment } from 'moment'

import style from './style.module.scss'

const DateTime = ({
  show,
  shows,
  countryId,
  selectedDate,
  setSelectedDate,
  selectedTime,
  setSelectedTime,
  selectedCategory,
}: any) => {
  const { t } = useTranslation()

  function onChange(value: Moment | null) {
    setSelectedDate(value)
    setSelectedTime(null)
  }

  const handleChangeTime = (e: Moment | null) => {
    if (e) {
      const hours = e.hour()
      const minutes = e.minutes()
      const slotsUnavailable = getQuartersSlots(selectedDate, hours, shows, countryId, selectedCategory)
      if (slotsUnavailable.find((slot) => slot === minutes) !== undefined) {
        const possibleQuarters = [0, 15, 30, 45].filter((quarter) => {
          const match = slotsUnavailable.find((slot) => slot === quarter)
          return match === undefined ? true : false
        })
        e.set('minutes', possibleQuarters[0] ?? 0)
      }
      setSelectedTime(e)
    }
  }

  const getDisabledTimes = () => {
    return {
      disabledHours: () => getHoursSlots(selectedDate, shows, countryId, selectedCategory),
      disabledMinutes: (selectedHour: number) =>
        getQuartersSlots(selectedDate, selectedHour, shows, countryId, selectedCategory),
    }
  }

  useEffect(() => {
    if (show?.categoryId === selectedCategory && !selectedTime) {
      setSelectedTime(moment(show.startAt))
      setSelectedDate(moment(show.startAt))
    }
  }, [selectedCategory])

  return (
    <>
      {/* <span className={`title_3 ${style.slide_2__title}`}>{t('createShowModalDateTimeTitle')}</span> */}

      <MaintenanceBanner />

      <div className={style.datePicker__container}>
        <InputLabel
          className="w-[100%] md:w-fit md:w-[300px]"
          title={t('createShowModalDateTimeInputLabelDate')}
          mandatory
        />
        <DatePicker
          className="w-[100%] md:w-fit md:w-[300px]"
          clearIcon={false}
          disabledDate={(date) => isDayDisabled(date, countryId, selectedCategory)}
          format="ddd Do MMMM YYYY"
          minuteStep={15}
          showNow={false}
          value={selectedDate}
          inputReadOnly
          onChange={onChange}
        />
      </div>
      <div className={style.timePicker__container}>
        <InputLabel
          className="w-[100%] md:w-fit md:w-[300px]"
          title={t('createShowModalDateTimeInputLabelHour')}
          mandatory
        />
        <TimePicker
          className="w-[100%] md:w-fit md:w-[300px]"
          disabled={!selectedDate}
          disabledTime={getDisabledTimes}
          format="HH:mm"
          minuteStep={15}
          secondStep={10}
          showNow={false}
          value={selectedTime}
          hideDisabledOptions
          inputReadOnly
          onSelect={handleChangeTime}
        />
      </div>
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    userId: state.authentication.user?.id,
    countryId: state.authentication.user?.sellerConfig?.countryId,
  }
}

export default connect(mapStateToProps)(DateTime)
