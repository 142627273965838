import * as Types from '../../network/graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateShowItemFragment = { __typename?: 'Show', id: string, legacyId: number, name: string, isBroadcasting: boolean, startAt: Date, currency: Types.Currency, thumbnailWebPUrl?: string | null, totalNetIncomeInCurrencyCents: number, totalCommissionInCurrencyCents: number, totalAmountOrdersSuccessInCurrencyCents: number, totalDeductedShippingCostInCurrencyCents: number, totalAmountOrdersNetInCurrencyCents: number };

export type CreateShowMutationVariables = Types.Exact<{
  input: Types.CreateShowInput;
}>;


export type CreateShowMutation = { __typename?: 'Mutation', createShow: { __typename?: 'CreateShowPayload', show: { __typename?: 'Show', id: string, legacyId: number, name: string, isBroadcasting: boolean, startAt: Date, currency: Types.Currency, thumbnailWebPUrl?: string | null, totalNetIncomeInCurrencyCents: number, totalCommissionInCurrencyCents: number, totalAmountOrdersSuccessInCurrencyCents: number, totalDeductedShippingCostInCurrencyCents: number, totalAmountOrdersNetInCurrencyCents: number } } };

export type CreatePreShowTeaserVideoUploadUrlMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type CreatePreShowTeaserVideoUploadUrlMutation = { __typename?: 'Mutation', createPreShowTeaserVideoUploadUrl: { __typename?: 'CreatePreShowTeaserVideoUploadUrlPayload', ok: boolean, uploadUrl: string, deleteUrl: string } };

export type UpdatePreShowTeaserVideoUrlMutationVariables = Types.Exact<{
  input: Types.UpdatePreShowTeaserVideoUrlInput;
}>;


export type UpdatePreShowTeaserVideoUrlMutation = { __typename?: 'Mutation', updatePreShowTeaserVideoUrl: { __typename?: 'UpdatePreShowTeaserVideoUrlPayload', show: { __typename?: 'Show', id: string, legacyId: number, name: string, isBroadcasting: boolean, startAt: Date, currency: Types.Currency, thumbnailWebPUrl?: string | null, totalNetIncomeInCurrencyCents: number, totalCommissionInCurrencyCents: number, totalAmountOrdersSuccessInCurrencyCents: number, totalDeductedShippingCostInCurrencyCents: number, totalAmountOrdersNetInCurrencyCents: number } } };

export type DeletePreShowTeaserVideoMutationVariables = Types.Exact<{
  input: Types.DeletePreShowTeaserVideoInput;
}>;


export type DeletePreShowTeaserVideoMutation = { __typename?: 'Mutation', deletePreShowTeaserVideo: { __typename?: 'DeletePreShowTeaserVideoPayload', ok: boolean, show: { __typename?: 'Show', id: string, preShowTeaserVideoUrl?: string | null } } };

export type GetDefaultShowLanguageQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetDefaultShowLanguageQuery = { __typename?: 'Query', viewer?: { __typename?: 'User', sellerConfig?: { __typename?: 'SellerConfig', defaultShowLanguage: { __typename?: 'Language', id: string, code: string } } | null } | null };

export type GetAllLanguagesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllLanguagesQuery = { __typename?: 'Query', allLanguages: Array<{ __typename?: 'Language', id: string, code: string }> };

export type UpdateShowMutationVariables = Types.Exact<{
  input: Types.UpdateShowInput;
}>;


export type UpdateShowMutation = { __typename?: 'Mutation', updateShow: { __typename?: 'UpdateShowPayload', show: { __typename?: 'Show', id: string, legacyId: number, name: string, isBroadcasting: boolean, startAt: Date, currency: Types.Currency, thumbnailWebPUrl?: string | null, totalNetIncomeInCurrencyCents: number, totalCommissionInCurrencyCents: number, totalAmountOrdersSuccessInCurrencyCents: number, totalDeductedShippingCostInCurrencyCents: number, totalAmountOrdersNetInCurrencyCents: number } } };

export const CreateShowItemFragmentDoc = gql`
    fragment CreateShowItem on Show {
  id
  legacyId
  name
  isBroadcasting
  startAt
  currency
  thumbnailWebPUrl(options: {width: 100})
  totalNetIncomeInCurrencyCents
  totalCommissionInCurrencyCents
  totalAmountOrdersSuccessInCurrencyCents
  totalDeductedShippingCostInCurrencyCents
  totalAmountOrdersNetInCurrencyCents
}
    `;
export const CreateShowDocument = gql`
    mutation CreateShow($input: CreateShowInput!) {
  createShow(input: $input) {
    show {
      ...CreateShowItem
    }
  }
}
    ${CreateShowItemFragmentDoc}`;
export type CreateShowMutationFn = Apollo.MutationFunction<CreateShowMutation, CreateShowMutationVariables>;

/**
 * __useCreateShowMutation__
 *
 * To run a mutation, you first call `useCreateShowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShowMutation, { data, loading, error }] = useCreateShowMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateShowMutation(baseOptions?: Apollo.MutationHookOptions<CreateShowMutation, CreateShowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateShowMutation, CreateShowMutationVariables>(CreateShowDocument, options);
      }
export type CreateShowMutationHookResult = ReturnType<typeof useCreateShowMutation>;
export type CreateShowMutationResult = Apollo.MutationResult<CreateShowMutation>;
export type CreateShowMutationOptions = Apollo.BaseMutationOptions<CreateShowMutation, CreateShowMutationVariables>;
export const CreatePreShowTeaserVideoUploadUrlDocument = gql`
    mutation CreatePreShowTeaserVideoUploadUrl {
  createPreShowTeaserVideoUploadUrl {
    ok
    uploadUrl
    deleteUrl
  }
}
    `;
export type CreatePreShowTeaserVideoUploadUrlMutationFn = Apollo.MutationFunction<CreatePreShowTeaserVideoUploadUrlMutation, CreatePreShowTeaserVideoUploadUrlMutationVariables>;

/**
 * __useCreatePreShowTeaserVideoUploadUrlMutation__
 *
 * To run a mutation, you first call `useCreatePreShowTeaserVideoUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePreShowTeaserVideoUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPreShowTeaserVideoUploadUrlMutation, { data, loading, error }] = useCreatePreShowTeaserVideoUploadUrlMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreatePreShowTeaserVideoUploadUrlMutation(baseOptions?: Apollo.MutationHookOptions<CreatePreShowTeaserVideoUploadUrlMutation, CreatePreShowTeaserVideoUploadUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePreShowTeaserVideoUploadUrlMutation, CreatePreShowTeaserVideoUploadUrlMutationVariables>(CreatePreShowTeaserVideoUploadUrlDocument, options);
      }
export type CreatePreShowTeaserVideoUploadUrlMutationHookResult = ReturnType<typeof useCreatePreShowTeaserVideoUploadUrlMutation>;
export type CreatePreShowTeaserVideoUploadUrlMutationResult = Apollo.MutationResult<CreatePreShowTeaserVideoUploadUrlMutation>;
export type CreatePreShowTeaserVideoUploadUrlMutationOptions = Apollo.BaseMutationOptions<CreatePreShowTeaserVideoUploadUrlMutation, CreatePreShowTeaserVideoUploadUrlMutationVariables>;
export const UpdatePreShowTeaserVideoUrlDocument = gql`
    mutation UpdatePreShowTeaserVideoUrl($input: UpdatePreShowTeaserVideoUrlInput!) {
  updatePreShowTeaserVideoUrl(input: $input) {
    show {
      ...CreateShowItem
    }
  }
}
    ${CreateShowItemFragmentDoc}`;
export type UpdatePreShowTeaserVideoUrlMutationFn = Apollo.MutationFunction<UpdatePreShowTeaserVideoUrlMutation, UpdatePreShowTeaserVideoUrlMutationVariables>;

/**
 * __useUpdatePreShowTeaserVideoUrlMutation__
 *
 * To run a mutation, you first call `useUpdatePreShowTeaserVideoUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePreShowTeaserVideoUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePreShowTeaserVideoUrlMutation, { data, loading, error }] = useUpdatePreShowTeaserVideoUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePreShowTeaserVideoUrlMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePreShowTeaserVideoUrlMutation, UpdatePreShowTeaserVideoUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePreShowTeaserVideoUrlMutation, UpdatePreShowTeaserVideoUrlMutationVariables>(UpdatePreShowTeaserVideoUrlDocument, options);
      }
export type UpdatePreShowTeaserVideoUrlMutationHookResult = ReturnType<typeof useUpdatePreShowTeaserVideoUrlMutation>;
export type UpdatePreShowTeaserVideoUrlMutationResult = Apollo.MutationResult<UpdatePreShowTeaserVideoUrlMutation>;
export type UpdatePreShowTeaserVideoUrlMutationOptions = Apollo.BaseMutationOptions<UpdatePreShowTeaserVideoUrlMutation, UpdatePreShowTeaserVideoUrlMutationVariables>;
export const DeletePreShowTeaserVideoDocument = gql`
    mutation DeletePreShowTeaserVideo($input: DeletePreShowTeaserVideoInput!) {
  deletePreShowTeaserVideo(input: $input) {
    show {
      id
      preShowTeaserVideoUrl
    }
    ok
  }
}
    `;
export type DeletePreShowTeaserVideoMutationFn = Apollo.MutationFunction<DeletePreShowTeaserVideoMutation, DeletePreShowTeaserVideoMutationVariables>;

/**
 * __useDeletePreShowTeaserVideoMutation__
 *
 * To run a mutation, you first call `useDeletePreShowTeaserVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePreShowTeaserVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePreShowTeaserVideoMutation, { data, loading, error }] = useDeletePreShowTeaserVideoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePreShowTeaserVideoMutation(baseOptions?: Apollo.MutationHookOptions<DeletePreShowTeaserVideoMutation, DeletePreShowTeaserVideoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePreShowTeaserVideoMutation, DeletePreShowTeaserVideoMutationVariables>(DeletePreShowTeaserVideoDocument, options);
      }
export type DeletePreShowTeaserVideoMutationHookResult = ReturnType<typeof useDeletePreShowTeaserVideoMutation>;
export type DeletePreShowTeaserVideoMutationResult = Apollo.MutationResult<DeletePreShowTeaserVideoMutation>;
export type DeletePreShowTeaserVideoMutationOptions = Apollo.BaseMutationOptions<DeletePreShowTeaserVideoMutation, DeletePreShowTeaserVideoMutationVariables>;
export const GetDefaultShowLanguageDocument = gql`
    query getDefaultShowLanguage {
  viewer {
    sellerConfig {
      defaultShowLanguage {
        id
        code
      }
    }
  }
}
    `;

/**
 * __useGetDefaultShowLanguageQuery__
 *
 * To run a query within a React component, call `useGetDefaultShowLanguageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDefaultShowLanguageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDefaultShowLanguageQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDefaultShowLanguageQuery(baseOptions?: Apollo.QueryHookOptions<GetDefaultShowLanguageQuery, GetDefaultShowLanguageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDefaultShowLanguageQuery, GetDefaultShowLanguageQueryVariables>(GetDefaultShowLanguageDocument, options);
      }
export function useGetDefaultShowLanguageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDefaultShowLanguageQuery, GetDefaultShowLanguageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDefaultShowLanguageQuery, GetDefaultShowLanguageQueryVariables>(GetDefaultShowLanguageDocument, options);
        }
export function useGetDefaultShowLanguageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetDefaultShowLanguageQuery, GetDefaultShowLanguageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDefaultShowLanguageQuery, GetDefaultShowLanguageQueryVariables>(GetDefaultShowLanguageDocument, options);
        }
export type GetDefaultShowLanguageQueryHookResult = ReturnType<typeof useGetDefaultShowLanguageQuery>;
export type GetDefaultShowLanguageLazyQueryHookResult = ReturnType<typeof useGetDefaultShowLanguageLazyQuery>;
export type GetDefaultShowLanguageSuspenseQueryHookResult = ReturnType<typeof useGetDefaultShowLanguageSuspenseQuery>;
export type GetDefaultShowLanguageQueryResult = Apollo.QueryResult<GetDefaultShowLanguageQuery, GetDefaultShowLanguageQueryVariables>;
export const GetAllLanguagesDocument = gql`
    query getAllLanguages {
  allLanguages {
    id
    code
  }
}
    `;

/**
 * __useGetAllLanguagesQuery__
 *
 * To run a query within a React component, call `useGetAllLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllLanguagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllLanguagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllLanguagesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllLanguagesQuery, GetAllLanguagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllLanguagesQuery, GetAllLanguagesQueryVariables>(GetAllLanguagesDocument, options);
      }
export function useGetAllLanguagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllLanguagesQuery, GetAllLanguagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllLanguagesQuery, GetAllLanguagesQueryVariables>(GetAllLanguagesDocument, options);
        }
export function useGetAllLanguagesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllLanguagesQuery, GetAllLanguagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllLanguagesQuery, GetAllLanguagesQueryVariables>(GetAllLanguagesDocument, options);
        }
export type GetAllLanguagesQueryHookResult = ReturnType<typeof useGetAllLanguagesQuery>;
export type GetAllLanguagesLazyQueryHookResult = ReturnType<typeof useGetAllLanguagesLazyQuery>;
export type GetAllLanguagesSuspenseQueryHookResult = ReturnType<typeof useGetAllLanguagesSuspenseQuery>;
export type GetAllLanguagesQueryResult = Apollo.QueryResult<GetAllLanguagesQuery, GetAllLanguagesQueryVariables>;
export const UpdateShowDocument = gql`
    mutation UpdateShow($input: UpdateShowInput!) {
  updateShow(input: $input) {
    show {
      ...CreateShowItem
    }
  }
}
    ${CreateShowItemFragmentDoc}`;
export type UpdateShowMutationFn = Apollo.MutationFunction<UpdateShowMutation, UpdateShowMutationVariables>;

/**
 * __useUpdateShowMutation__
 *
 * To run a mutation, you first call `useUpdateShowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShowMutation, { data, loading, error }] = useUpdateShowMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateShowMutation(baseOptions?: Apollo.MutationHookOptions<UpdateShowMutation, UpdateShowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateShowMutation, UpdateShowMutationVariables>(UpdateShowDocument, options);
      }
export type UpdateShowMutationHookResult = ReturnType<typeof useUpdateShowMutation>;
export type UpdateShowMutationResult = Apollo.MutationResult<UpdateShowMutation>;
export type UpdateShowMutationOptions = Apollo.BaseMutationOptions<UpdateShowMutation, UpdateShowMutationVariables>;