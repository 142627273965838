import { useCallback, useEffect, useRef, useState } from 'react'

import Carousel from '@/components/Carousel'
import Dialog from '@/components/ui/Dialog/Dialog'

import { useGetCommercialEventApplicationsQuery } from '../../operations.generated'

import CommercialEventStep1 from './components/applicationSteps/CommercialEventStep1'
import PreliminaryStep from './components/applicationSteps/PreliminaryStep'
import ShowEventApplicationFormStep1 from './components/applicationSteps/ShowEventApplicationFormStep1'
import ShowEventApplicationFormStep2 from './components/applicationSteps/ShowEventApplicationFormStep2'
import ShowEventApplicationFormStep3 from './components/applicationSteps/ShowEventApplicationFormStep3'
import ApplyForShowEventModalHeader from './components/ApplyForShowEventModalHeader'

import type { ApplicationType, CommercialEventApplications } from './types'
import type {
  CommercialEventApplicationCriteria,
  FeaturedShowApplicationStatus,
} from '../../../../network/graphql/types.generated'

import './ApplyForShowEvent.scss'

type ApplyForShowEventModalProps = {
  setIsOpen: (isOpen: boolean) => void
  isOpen: boolean
  showId: string
  showGlobalParentCategoryId: string
  showCategorySlug: string
  commercialEventApplicationCriteria: CommercialEventApplicationCriteria
  featuredShowApplicationStatus: FeaturedShowApplicationStatus | null
}

const ApplyForShowEventModal = (props: ApplyForShowEventModalProps) => {
  const {
    setIsOpen,
    isOpen,
    showId,
    showGlobalParentCategoryId,
    showCategorySlug,
    commercialEventApplicationCriteria,
    featuredShowApplicationStatus,
  } = props

  const [selectedSlide, setSelectedSlide] = useState<number>(0)
  const [featuredShowTargetInEuros, setFeaturedShowTargetInEuros] = useState<number>(0)
  const [applicationType, setApplicationType] = useState<ApplicationType | null>(null)
  const [commercialEventApplications, setCommercialEventApplications] = useState<CommercialEventApplications>(null)

  const carouselRef = useRef<any>(null)

  const { data } = useGetCommercialEventApplicationsQuery({
    variables: { showId },
    skip: !isOpen,
  })

  useEffect(() => {
    if (data?.node?.__typename === 'Show') {
      setCommercialEventApplications(data.node.commercialEventApplications ?? null)
    }
  }, [data])

  const handleNextSlide = useCallback(() => {
    if (carouselRef.current) {
      carouselRef.current.next()
    }
    setSelectedSlide((prev) => prev + 1)
  }, [])

  const handlePreviousSlide = useCallback(() => {
    if (carouselRef.current) {
      carouselRef.current.prev()
    }
    setSelectedSlide((prev) => {
      if (prev <= 1) {
        setApplicationType(null)
        return 0
      }
      return prev - 1
    })
  }, [])

  const handleClose = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  const handleSelectType = useCallback((application: ApplicationType) => {
    setApplicationType(application)
    setSelectedSlide(1)
    if (carouselRef.current) {
      carouselRef.current.goTo(1)
    }
  }, [])

  const renderSlides = useCallback(() => {
    const slides = [
      <PreliminaryStep
        key="preliminary"
        commercialEventApplications={commercialEventApplications}
        featuredShowApplicationStatus={featuredShowApplicationStatus}
        showId={showId}
        onSelectType={handleSelectType}
      />,
    ]

    if (applicationType?.type === 'featureShow') {
      slides.push(
        <ShowEventApplicationFormStep1
          key="step1"
          handleNextSlide={handleNextSlide}
          setFeaturedShowTargetInEuros={setFeaturedShowTargetInEuros}
          showId={showId}
        />,
        <ShowEventApplicationFormStep2
          key="step2"
          featuredShowTargetInEuros={featuredShowTargetInEuros}
          handleNextSlide={handleNextSlide}
          showCategorySlug={showCategorySlug}
          showId={showId}
          showParentCategoryGlobalId={showGlobalParentCategoryId}
        />,
        <ShowEventApplicationFormStep3 key="step3" handleCloseModal={handleClose} />
      )
    } else if (applicationType?.type === 'commercialEvent') {
      slides.push(
        <CommercialEventStep1
          key="step1"
          commercialEventApplicationCriteria={commercialEventApplicationCriteria}
          commercialEventBanner={applicationType.banner}
          commercialEventDescription={applicationType.description}
          commercialEventEndsAt={applicationType.endAt}
          commercialEventId={applicationType.id}
          commercialEventName={applicationType.name}
          commercialEventStartsAt={applicationType.startAt}
          handleNextSlide={handleNextSlide}
          showId={showId}
        />,
        <ShowEventApplicationFormStep3 key="step3" handleCloseModal={handleClose} />
      )
    }

    return slides
  }, [
    applicationType,
    commercialEventApplications,
    featuredShowApplicationStatus,
    showId,
    handleSelectType,
    handleNextSlide,
    featuredShowTargetInEuros,
    showCategorySlug,
    showGlobalParentCategoryId,
    handleClose,
    commercialEventApplicationCriteria,
  ])

  return (
    <Dialog
      className="apply-for-show-event custom-dialog"
      isOpen={isOpen}
      title={<ApplyForShowEventModalHeader handlePreviousSlide={handlePreviousSlide} selectedSlide={selectedSlide} />}
      modal
      onClose={handleClose}
    >
      <div className="dialog-scrollable-content">
        <Carousel ref={carouselRef} selectedSlide={selectedSlide}>
          {renderSlides()}
        </Carousel>
      </div>
    </Dialog>
  )
}

export default ApplyForShowEventModal
