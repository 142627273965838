import Button from '@/components/ui/Button/Button'

import EligibilityCard from '../EligibilityCard'

import EventHeader from './EventHeader'

type Criterium = {
  isCompleted: boolean
  title: string
}

type ApplicationFormStep1Props = {
  criteria: Criterium[]
  canApply: boolean
  onNext: () => void
  headerTitle: string
  headerBanner?: string
  headerDescription?: string
  buttonText: string
  isLoading?: boolean
  startAt?: Date
  endAt?: Date
}

const ApplicationFormStep1 = ({
  criteria,
  canApply,
  onNext,
  headerTitle,
  headerBanner,
  headerDescription,
  buttonText,
  startAt,
  endAt,
  isLoading = false,
}: ApplicationFormStep1Props) => {
  return (
    <div className="form-step1">
      <EventHeader
        banner={headerBanner}
        description={headerDescription}
        endAt={endAt}
        startAt={startAt}
        title={headerTitle}
      />
      <div className="criteria-list">
        {criteria.map((criterium) => (
          <EligibilityCard key={criterium.title} isCompleted={criterium.isCompleted} title={criterium.title} />
        ))}
      </div>
      <div className="next-slide-container">
        <Button className="primary next-slide-button" disabled={!canApply || isLoading} onClick={onNext}>
          {buttonText}
        </Button>
      </div>
    </div>
  )
}

export default ApplicationFormStep1
