import { useTranslation } from 'react-i18next'

import { useGetFeaturedShowApplicationCriteriaQuery } from '../../../../operations.generated'

import ApplicationFormStep1 from './ApplicationFormStep1'

type ShowEventApplicationFormStep1Props = {
  showId: string
  handleNextSlide: () => void
  setFeaturedShowTargetInEuros: (value: number) => void
}

const ShowEventApplicationFormStep1 = ({
  showId,
  handleNextSlide,
  setFeaturedShowTargetInEuros,
}: ShowEventApplicationFormStep1Props) => {
  const { t } = useTranslation()

  const { data, loading: auctionDurationLoading } = useGetFeaturedShowApplicationCriteriaQuery({
    skip: !showId,
    variables: { showId: showId },
  })

  const {
    hasDoneEnoughShowsOnVoggt = false,
    isSellerRatingHighEnough = false,
    hasCreatedEnoughItemsWithPhotos = false,
    isShowDateInFutureEnough = false,
    featuredShowTarget: { isShowGMVTargetHighEnough = false, featuredShowTargetInEuros = 0 } = {},
  } = data?.node?.__typename === 'Show' ? data.node.featuredShowApplicationCriteria : {}

  setFeaturedShowTargetInEuros(featuredShowTargetInEuros)

  const canApplyToShowEvent =
    hasDoneEnoughShowsOnVoggt &&
    isSellerRatingHighEnough &&
    hasCreatedEnoughItemsWithPhotos &&
    isShowDateInFutureEnough &&
    isShowGMVTargetHighEnough

  const criteria = [
    { isCompleted: hasDoneEnoughShowsOnVoggt, title: t('showEventApplicationCriteriaHasDoneEnoughShowsOnVoggt') },
    { isCompleted: isSellerRatingHighEnough, title: t('showEventApplicationCriteriaIsSellerRatingHighEnough') },
    {
      isCompleted: hasCreatedEnoughItemsWithPhotos,
      title: t('showEventApplicationCriteriaHasCreatedEnoughItemsWithPhotos'),
    },
    { isCompleted: isShowDateInFutureEnough, title: t('showEventApplicationCriteriaIsShowDateInFutureEnough') },
    {
      isCompleted: isShowGMVTargetHighEnough,
      title: t('showEventApplicationCriteriaIsShowGMVTargetHighEnough', { featuredShowTargetInEuros }),
    },
  ]

  return (
    <ApplicationFormStep1
      buttonText={t('commonNext')}
      canApply={canApplyToShowEvent}
      criteria={criteria}
      headerTitle={t('featuredShowApplicationTitle')}
      isLoading={auctionDurationLoading}
      onNext={handleNextSlide}
    />
  )
}

export default ShowEventApplicationFormStep1
