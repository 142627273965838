import { useTranslation } from 'react-i18next'
import { FaChevronLeft } from 'react-icons/fa6'

import Button from '@/components/ui/Button/Button'

type ApplyForShowEventModalHeaderProps = {
  selectedSlide: number
  handlePreviousSlide: () => void
}

const ApplyForShowEventModalHeader = ({ selectedSlide, handlePreviousSlide }: ApplyForShowEventModalHeaderProps) => {
  const { t } = useTranslation()

  return selectedSlide === 0 ? (
    <div className="header">
      <div className="title-container">
        <div className="text-container">
          <p className="title">{t('showEventApplicationHeaderTitle')}</p>
          <p className="subtitle">{t('showEventApplicationHeaderSubtitle')}</p>
        </div>
      </div>
    </div>
  ) : (
    <div className="header">
      <Button
        className="button action close-action"
        icon={<FaChevronLeft />}
        title={t('commonBack')}
        onClick={handlePreviousSlide}
      />
    </div>
  )
}

export default ApplyForShowEventModalHeader
