import { Drawer, Modal, Steps } from 'antd'
import axios from 'axios'
import moment from 'moment'
import { useMemo, useState, useRef, useEffect } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { FaXmark } from 'react-icons/fa6'
import { useDispatch, connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { fetchAllShowsSilent } from '@/actions/fetch-all-shows-silent'
import { fetchShow } from '@/actions/fetch-show'
import { updateShow } from '@/actions/update-show'
import { uploadShowThumbnail } from '@/actions/upload-show-thumbnail'
import Button from '@/components/ui/Button/Button'
import Config, { DEFAULT_COUNTRY_ID } from '@/config/config'
import { getLegacyId } from '@/helpers/getLegacyId'
import { useAppSelector } from '@/reducers'
import { selectShowById } from '@/selectors/shows'
import { trackError } from '@/util/sentry'
import { GetAllShowsDocument, GetSellerShowsAggregatedByMonthDocument } from '@/views/Shows/operations.generated'

import Carousel from '../Carousel'
import CategoriesCloud from '../CategoriesCloud/CategoriesCloud'
import { VideoUploadedStatus } from '../CreateShow/components/VideoUploader/VideoUploader.container'
import InfoLabel from '../InfoLabel'
import Alert from '../ui/Alert/Alert'
import { notificationDanger, notificationSuccess } from '../ui/Notification/Notification'

import DateTime from './DateTime'
import {
  useCreatePreShowTeaserVideoUploadUrlMutation,
  useCreateShowMutation,
  useUpdatePreShowTeaserVideoUrlMutation,
} from './operations.generated'
import ShowDetailsFormFields from './ShowDetailsFormFields/ShowDetailsFormFields'

import type { OnSubmitCreateShowInput, OnSubmitUpdateShowInput, PreShowTeaserVideoInput } from './types'
import type { Category } from '@/network/graphql/types.generated'
import type { CustomUrlPreshow, Show } from '@/types'
import type { UploadFile } from 'antd/lib/upload/interface'
import type { Moment } from 'moment'

import style from './style.module.scss'
import globalStyle from '../../styles/_export.module.scss'

const { VITE_APP_LONG_TIMEOUT } = Config

interface CreateShowModalProps {
  showId?: number
  setIsModalVisible: (isVisible: boolean) => void
  providedCategory?: number | null
  providedSlot?: Moment | null
  isOnlyCategoryAndDate?: boolean
  isThumbnailLoading?: boolean
  sellerCountry?: string
  onShowCreated?: (show: any) => void
  onShowUpdated?: () => void
  createShop?: boolean
}

const CreateShowModal = (props: CreateShowModalProps) => {
  const {
    showId,
    setIsModalVisible,
    providedCategory,
    providedSlot,
    isOnlyCategoryAndDate = false,
    isThumbnailLoading,
    sellerCountry,
    onShowCreated,
    onShowUpdated,
    createShop = false,
  } = props
  const isComingWithProvidedData = providedCategory && providedSlot

  const { t } = useTranslation()
  const carousel = useRef<any>()
  const dispatch = useDispatch<any>()
  const navigate = useNavigate()

  const show = useAppSelector(showId ? (state) => selectShowById(state, showId) : () => null)

  const [selectedCategory, setSelectedCategory] = useState<any>(providedCategory || 0)
  const [selectedDate, setSelectedDate] = useState<Moment | null>(providedSlot || null)
  const [selectedTime, setSelectedTime] = useState<Moment | null>(providedSlot || null)
  const [selectedSlide, setSelectedSlide] = useState<number>(isComingWithProvidedData ? 2 : 0)
  const [isFormComplete, setIsFormComplete] = useState<boolean>(false)
  const [isCreateLoading, setIsCreateLoading] = useState<boolean>(false)
  const [isUpdateLoading, setIsUpdateLoading] = useState<boolean>(false)
  const [allShows, setAllShows] = useState<Show[] | null>(null)
  const [videoUploadStatus, setVideoUploadStatus] = useState<VideoUploadedStatus>(VideoUploadedStatus.NO_VIDEO)
  const [customUrlPreShow, setCustomUrlPreShow] = useState<CustomUrlPreshow>({
    get: '',
    put: '',
    delete: '',
  })

  const [createShowMutation, { client }] = useCreateShowMutation({})
  const [createPreShowTeaserVideoUploadUrl] = useCreatePreShowTeaserVideoUploadUrlMutation()
  const [updatePreShowTeaserVideoUrl] = useUpdatePreShowTeaserVideoUrlMutation()

  const footerSteps = useMemo(
    () =>
      [{ title: t('createShowModalFooterStep1Title') }, { title: t('createShowModalFooterStep2Title') }].concat(
        isOnlyCategoryAndDate ? [] : [{ title: t('createShowModalFooterStep3Title') }]
      ),
    [t, isOnlyCategoryAndDate]
  )

  const handleCategorySelect = (category: Category) => {
    const { id, isOffline } = category
    setSelectedCategory(getLegacyId(id))
    if (isOffline) {
      setSelectedSlide(2)
      const date = moment().add(89, 'day').startOf('day')
      setSelectedDate(date)
      setSelectedTime(date)
    } else {
      setSelectedSlide(1)
    }
  }

  const getShows = async () => {
    const { payload: shows } = await dispatch(fetchAllShowsSilent())

    if (!shows) {
      return // Avoid a filter on null object
    }

    const showsByCountry = shows.filter((show: Show) => show.countryId === sellerCountry)
    setAllShows(showsByCountry)
  }

  const getCustomUrlPreShow = () => {
    createPreShowTeaserVideoUploadUrl({
      context: {
        timeout: VITE_APP_LONG_TIMEOUT ? parseInt(VITE_APP_LONG_TIMEOUT) : 45000,
      },
      onCompleted: (data) => {
        const customUrlPut = data.createPreShowTeaserVideoUploadUrl.uploadUrl
        const newUrl = new URL(customUrlPut)
        newUrl.search = ''
        const customUrlGet = newUrl.toString()
        setCustomUrlPreShow({
          get: customUrlGet,
          put: customUrlPut,
          delete: data.createPreShowTeaserVideoUploadUrl.deleteUrl,
        })
      },
      onError: (error) => {
        trackError(error, { meta: { feature: 'show.creation.preShowTeaserVideo.createUploadUrl' } })
      },
    })
  }

  const handleCancel = () => {
    if (customUrlPreShow.delete === '') return
    if (videoUploadStatus === VideoUploadedStatus.VIDEO_UPLOADED) {
      axios.delete(customUrlPreShow.delete).catch((error) => {
        trackError(error, {
          meta: {
            customUrlPreShow,
            feature: 'show.creation.cancellation.preShowTeaserVideo.deleteUploaded',
          },
        })
      })
    }

    setIsModalVisible(false)
  }

  const uploadThumbnailOnShow = async (showId: number, thumbnail: UploadFile<any>) => {
    const thumbnailUpload = await dispatch(
      uploadShowThumbnail({
        showId,
        thumbnail: thumbnail as unknown as File,
      })
    )

    if (thumbnailUpload.type.indexOf('rejected') !== -1) {
      notificationDanger(thumbnailUpload?.payload?.details)
    }
  }

  const onSubmitCreateShow = async (
    input: OnSubmitCreateShowInput,
    thumbnail: UploadFile<any> | undefined,
    teaserVideo: PreShowTeaserVideoInput | undefined
  ) => {
    // TODO: how to properly handle this form validation?
    if (!thumbnail || isCreateLoading) {
      return
    }

    setIsCreateLoading(true)

    const { languageId } = input
    await createShowMutation({
      variables: {
        input: {
          ...input,
          languageId: `Language|${languageId}`,
        },
      },
      onError: (error) => {
        // TODO: handle 400 errors

        notificationDanger(error.message)

        setIsCreateLoading(false)
      },
      onCompleted: async ({ createShow }) => {
        const showLegacyId = createShow?.show?.legacyId

        if (thumbnail && showLegacyId) {
          await uploadThumbnailOnShow(showLegacyId, thumbnail)
        }

        if (teaserVideo) {
          await handleTeaserVideoUpdate(showLegacyId, teaserVideo)
        }

        setIsCreateLoading(false)

        setIsModalVisible(false)
        onShowCreated && onShowCreated(createShow.show)
        if (createShop) {
          notificationSuccess(t('createShopModalDetailsSuccessMessage'))
          navigate('/shops/' + showLegacyId)
        } else {
          notificationSuccess(t('createShowModalDetailsSuccessMessage'))
          navigate('/shows/' + showLegacyId)
        }
      },
      update: (_, { data: createShowsData }) => {
        if (!createShowsData?.createShow) return
        client.refetchQueries({
          include: [GetAllShowsDocument, GetSellerShowsAggregatedByMonthDocument],
        })
      },
    })
  }

  const onSubmitUpdateShow = async (
    input: OnSubmitUpdateShowInput,
    thumbnail: UploadFile<any> | undefined,
    teaserVideo: PreShowTeaserVideoInput | undefined
  ) => {
    if (!showId || isUpdateLoading) {
      return
    }

    setIsUpdateLoading(true)

    // TODO: use a graphql mutation instead of the action + REST API call
    const result = await dispatch(
      updateShow({
        showId,
        ...input,
      })
    )
    const { type } = result

    // TODO: shouldn't this be handled in a separate function?
    if (thumbnail) {
      await uploadThumbnailOnShow(showId, thumbnail)
    }

    // TODO: shouldn't this be handled in a separate function?
    if (teaserVideo) {
      await handleTeaserVideoUpdate(showId, teaserVideo)
    }

    setIsUpdateLoading(false)

    if (type.indexOf('rejected') !== -1) {
      notificationDanger(result?.payload?.details)
    } else {
      notificationSuccess(t('createShowModalDetailsUpdateMessage'))
      setIsModalVisible(false)

      if (onShowUpdated) {
        onShowUpdated()
      }
    }
  }

  const handleTeaserVideoUpdate = async (showId: number, teaserVideo: PreShowTeaserVideoInput) => {
    if (!showId || !teaserVideo) {
      return
    }

    if (videoUploadStatus !== VideoUploadedStatus.VIDEO_UPLOADED) {
      return
    }

    const { originalFilename, originalFilesize } = teaserVideo

    await updatePreShowTeaserVideoUrl({
      variables: {
        input: {
          showId: `Show|${showId}`,
          uploadUrl: customUrlPreShow.get,
          originalFileInfo: {
            name: originalFilename,
            size: originalFilesize,
          },
        },
      },
      onError(error) {
        notificationDanger(error.message)
      },
    })
  }

  useEffect(() => {
    if (show && show.preShowTeaserVideoInfo?.transcodingInfo?.status === 'in_progress') {
      // The video is still processing in the background
      setVideoUploadStatus(VideoUploadedStatus.VIDEO_TRANSCODING)
    } else if (show && show.preShowTeaserVideoInfo?.mp4HdFileInfo) {
      // We set the video upload status to VIDEO_PREVIOUSLY_UPDATED if we update a show with a video
      // else the VideoUploadStatus will be "none"
      setVideoUploadStatus(VideoUploadedStatus.VIDEO_PREVIOUSLY_UPLOADED)
    }
  }, [show])

  useEffect(() => {
    if (customUrlPreShow.put === '') {
      getCustomUrlPreShow()
    }
  })

  useEffect(() => {
    if (carousel?.current) {
      carousel.current.goTo(selectedSlide)
    }
  }, [selectedSlide])

  useEffect(() => {
    if (allShows === null && !providedSlot && !providedCategory) {
      getShows()
    }
  }, [dispatch, allShows])

  useEffect(() => {
    if (showId) dispatch(fetchShow(showId))
  }, [dispatch, showId])

  const getModalTitle = () => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span>
          {createShop
            ? `${t('createShopModalTitle', {
                cta: show ? t('createShowModalButtonUpdate') : t('createShowModalButtonCreate'),
              })}`
            : `${t('createShowModalTitle', {
                cta: show ? t('createShowModalButtonUpdate') : t('createShowModalButtonCreate'),
              })}`}
        </span>
        {isComingWithProvidedData && (
          <div style={{ marginLeft: 16 }}>
            <InfoLabel message={selectedDate?.format('dddd LLL')} type="info" />
          </div>
        )}
      </div>
    )
  }

  const isEventShow = show?.isFeatured || show?.isPremier

  const renderFooter = () => {
    const isValidateButtonDisabled = !isFormComplete
    const isValidateButtonLoading =
      isCreateLoading ||
      isUpdateLoading ||
      isThumbnailLoading ||
      videoUploadStatus === VideoUploadedStatus.VIDEO_UPLOADING

    return (
      <div className={style.footer__container}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '12px',
            }}
          >
            {selectedSlide === 1 && isEventShow && (
              <Alert emphasis="low" type="warning">
                {t('createShowModalFeaturedShowUpdateWarning')}
              </Alert>
            )}
            {selectedSlide === 1 && (
              <Button
                className="next-step-action primary"
                disabled={!selectedDate || !selectedTime}
                label={t('createShowModalButtonNext')}
                style={{ width: 'auto', alignSelf: 'end' }}
                preventDefault
                onClick={() => setSelectedSlide(selectedSlide + 1)}
              />
            )}
          </div>
          {selectedSlide !== 1 && (
            <Button
              className="validate-action primary"
              disabled={isValidateButtonDisabled}
              form="myForm"
              htmlType="submit"
              isLoading={isValidateButtonLoading}
              label={show ? t('createShowModalButtonUpdate') : t('createShowModalButtonCreate')}
            />
          )}
        </div>

        {!isComingWithProvidedData && (
          <div className={style.steps__container} style={{ marginTop: '10px' }}>
            <Steps current={selectedSlide} items={footerSteps} size="small" />
          </div>
        )}
      </div>
    )
  }

  if (isMobileOnly) {
    return (
      <Drawer
        className="create_show_modal"
        closeIcon={<FaXmark color={globalStyle.black150} size={12} />}
        destroyOnClose={true}
        footer={renderFooter()}
        open={true}
        title={getModalTitle()}
        width="100vw"
        onClose={handleCancel}
      >
        <Carousel ref={carousel} accessibility={false} dots={false} selectedSlide={selectedSlide} swipe={false}>
          <div>
            <span className={`title_3 ${style.slide_1__title}`}>{t('createShowModalStep1Title')}</span>
            <CategoriesCloud
              createShop={createShop}
              handleCategorySelect={handleCategorySelect}
              selectedCategory={show ? show.categoryId : selectedCategory}
            />
          </div>
          <div>
            <DateTime
              countryId={sellerCountry}
              selectedCategory={selectedCategory}
              selectedDate={selectedDate}
              selectedTime={selectedTime}
              setSelectedDate={setSelectedDate}
              setSelectedTime={setSelectedTime}
              show={show}
              shows={allShows}
            />
          </div>
          <div>
            <ShowDetailsFormFields
              categoryId={selectedCategory}
              customUrlPreShow={customUrlPreShow}
              date={selectedDate}
              isOnlyCategoryAndDate={isOnlyCategoryAndDate}
              setAllFormComplete={setIsFormComplete}
              setVideoUploadStatus={setVideoUploadStatus}
              show={show}
              time={selectedTime}
              videoUploadStatus={videoUploadStatus}
              onSubmitCreateShow={onSubmitCreateShow}
              onSubmitUpdateShow={onSubmitUpdateShow}
            />
          </div>
        </Carousel>
      </Drawer>
    )
  }

  return (
    <Modal
      className="create_show_modal"
      closeIcon={<FaXmark color={globalStyle.black150} size={12} />}
      destroyOnClose={true}
      footer={renderFooter()}
      open={true}
      style={{ top: 20, width: 500 }}
      title={getModalTitle()}
      onCancel={handleCancel}
    >
      <Carousel ref={carousel} accessibility={false} dots={false} selectedSlide={selectedSlide} swipe={false}>
        <div>
          <span className={`title_3 ${style.slide_1__title}`}>{t('createShowModalStep1Title')}</span>
          <CategoriesCloud
            createShop={createShop}
            handleCategorySelect={handleCategorySelect}
            selectedCategory={show ? show.categoryId : selectedCategory}
          />
        </div>
        <div>
          <DateTime
            countryId={sellerCountry}
            selectedCategory={selectedCategory}
            selectedDate={selectedDate}
            selectedTime={selectedTime}
            setSelectedDate={setSelectedDate}
            setSelectedTime={setSelectedTime}
            show={show}
            shows={allShows}
          />
        </div>
        <div>
          <ShowDetailsFormFields
            categoryId={selectedCategory}
            createShop={createShop}
            customUrlPreShow={customUrlPreShow}
            date={selectedDate}
            isOnlyCategoryAndDate={isOnlyCategoryAndDate}
            setAllFormComplete={setIsFormComplete}
            setVideoUploadStatus={setVideoUploadStatus}
            show={show}
            time={selectedTime}
            videoUploadStatus={videoUploadStatus}
            onSubmitCreateShow={onSubmitCreateShow}
            onSubmitUpdateShow={onSubmitUpdateShow}
          />
        </div>
      </Carousel>
    </Modal>
  )
}

function mapStateToProps(state: any) {
  const { shows } = state
  return {
    isThumbnailLoading: shows.isThumbnailLoading,
    sellerCountry: state.authentication.user?.sellerConfig?.countryId || DEFAULT_COUNTRY_ID,
  }
}

export default connect(mapStateToProps)(CreateShowModal)
