import { useTranslation } from 'react-i18next'

import ArrowRightIcon from '@/components/ui/Icons/ArrowIcon/ArrowRightIcon'

import { FeaturedShowApplicationStatus } from '../../../../../../network/graphql/types.generated'

import type { ApplicationStatus } from '../../types'

type ApplicationStatusTagProps = {
  status: ApplicationStatus | null
}

const ApplicationStatusTag = ({ status }: ApplicationStatusTagProps) => {
  const { t } = useTranslation()

  const getTagProps = (status: ApplicationStatus | null) => {
    switch (status) {
      case FeaturedShowApplicationStatus.Pending:
        return {
          className: 'status-tag status-pending',
          label: t('showEventApplicationStatusButtonInReview'),
          disabled: true,
        }
      case FeaturedShowApplicationStatus.Approved:
        return {
          className: 'status-tag status-approved',
          label: t('showEventApplicationStatusButtonApproved'),
          disabled: true,
        }
      case FeaturedShowApplicationStatus.Refused:
        return {
          className: 'status-tag status-rejected',
          label: t('showEventApplicationStatusButtonRejected'),
          disabled: true,
        }
      default:
        return {
          icon: <ArrowRightIcon />,
        }
    }
  }

  const tagProps = getTagProps(status)

  return (
    <div className={`application-status-tag ${tagProps.className}`}>
      <span>{tagProps.label}</span>
      {tagProps.icon && <span className="icon">{tagProps.icon}</span>}
    </div>
  )
}

export default ApplicationStatusTag
