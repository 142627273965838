import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

type DateContainerProps = {
  startAt: Date | undefined
  endAt: Date | undefined
}

export const DateContainer = ({ startAt, endAt }: DateContainerProps) => {
  const { t } = useTranslation()
  return (
    <div className="application-option-date-container">
      <p className="application-option-date">
        {t('eventStartsAt')} {startAt ? dayjs(startAt).format('DD-MM-YYYY') : ''}
      </p>
      <p className="application-option-date">
        {t('eventEndsOn')} {endAt ? dayjs(endAt).format('DD-MM-YYYY') : ''}
      </p>
    </div>
  )
}
