import ApplicationStatusTag from './ApplicationStatusTag'
import { DateContainer } from './DateContainer'

import type { ApplicationStatus } from '../../types'
import type { ReactNode } from 'react'

type ApplicationOptionProps = {
  icon: ReactNode
  status: ApplicationStatus | null
  title: string
  subTitle?: string
  startAt?: Date
  endAt?: Date
  isCommercialEvent?: boolean
  onClick: () => void
}

const ApplicationOption = ({
  icon,
  onClick,
  status,
  title,
  endAt,
  isCommercialEvent,
  startAt,
  subTitle,
}: ApplicationOptionProps) => {
  const isDisabled = status !== null
  const handleClick = isDisabled ? undefined : onClick

  return (
    <div className={`application-option ${isDisabled ? 'disabled' : ''}`} onClick={handleClick}>
      <div className="application-option-icon">{icon}</div>
      <div className="application-option-content">
        <h2 className="application-option-title">{title}</h2>
        {isCommercialEvent && (
          <>
            <p className="application-option-subtitle">{subTitle}</p>
            <DateContainer endAt={endAt} startAt={startAt} />
          </>
        )}
      </div>

      <div className="application-option-arrow">
        <ApplicationStatusTag status={status} />
      </div>
    </div>
  )
}

export default ApplicationOption
