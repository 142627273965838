import * as Types from '../../../../network/graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetShowNotificationQueryVariables = Types.Exact<{
  nodeId: Types.Scalars['ID']['input'];
}>;


export type GetShowNotificationQuery = { __typename?: 'Query', node: { __typename?: 'AutoFollowUserCampaign' } | { __typename?: 'AutocompleteSearchItem' } | { __typename?: 'Badge' } | { __typename?: 'Category' } | { __typename?: 'CommercialEvent' } | { __typename?: 'Country' } | { __typename?: 'Emote' } | { __typename?: 'FavoriteProduct' } | { __typename?: 'FavoriteShow' } | { __typename?: 'ImageUpload' } | { __typename?: 'InventoryProduct' } | { __typename?: 'Language' } | { __typename?: 'LoyaltyLevel' } | { __typename?: 'Order' } | { __typename?: 'OrderedProduct' } | { __typename?: 'ParentCategory' } | { __typename?: 'Product' } | { __typename?: 'ProductForOrdering' } | { __typename?: 'Promotion' } | { __typename?: 'PromotionV2' } | { __typename?: 'RatingFromCustomer' } | { __typename?: 'SellerLedgerPayout' } | { __typename?: 'SellerLedgerTransaction' } | { __typename?: 'SellerShowsAggregatedByMonth' } | { __typename?: 'SellerTier' } | { __typename?: 'Shipment' } | { __typename?: 'Shop' } | { __typename?: 'Show', id: string, name: string, nextNotificationToSend?: { __typename?: 'Notification', id: string, title: string, body: string, sendableAt: Date } | null } | { __typename?: 'ShowOrdersGroup' } | { __typename?: 'ShowProductNode' } | { __typename?: 'User' } };

export type SendShowNotificationMutationVariables = Types.Exact<{
  input: Types.SendNotificationForShowInput;
}>;


export type SendShowNotificationMutation = { __typename?: 'Mutation', sendNotificationForShow: { __typename?: 'SendNotificationForShowPayload', ok: boolean } };


export const GetShowNotificationDocument = gql`
    query GetShowNotification($nodeId: ID!) {
  node(id: $nodeId) {
    ... on Show {
      id
      name
      nextNotificationToSend {
        id
        title
        body
        sendableAt
      }
    }
  }
}
    `;

/**
 * __useGetShowNotificationQuery__
 *
 * To run a query within a React component, call `useGetShowNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShowNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShowNotificationQuery({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useGetShowNotificationQuery(baseOptions: Apollo.QueryHookOptions<GetShowNotificationQuery, GetShowNotificationQueryVariables> & ({ variables: GetShowNotificationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetShowNotificationQuery, GetShowNotificationQueryVariables>(GetShowNotificationDocument, options);
      }
export function useGetShowNotificationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetShowNotificationQuery, GetShowNotificationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetShowNotificationQuery, GetShowNotificationQueryVariables>(GetShowNotificationDocument, options);
        }
export function useGetShowNotificationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetShowNotificationQuery, GetShowNotificationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetShowNotificationQuery, GetShowNotificationQueryVariables>(GetShowNotificationDocument, options);
        }
export type GetShowNotificationQueryHookResult = ReturnType<typeof useGetShowNotificationQuery>;
export type GetShowNotificationLazyQueryHookResult = ReturnType<typeof useGetShowNotificationLazyQuery>;
export type GetShowNotificationSuspenseQueryHookResult = ReturnType<typeof useGetShowNotificationSuspenseQuery>;
export type GetShowNotificationQueryResult = Apollo.QueryResult<GetShowNotificationQuery, GetShowNotificationQueryVariables>;
export const SendShowNotificationDocument = gql`
    mutation SendShowNotification($input: SendNotificationForShowInput!) {
  sendNotificationForShow(input: $input) {
    ok
  }
}
    `;
export type SendShowNotificationMutationFn = Apollo.MutationFunction<SendShowNotificationMutation, SendShowNotificationMutationVariables>;

/**
 * __useSendShowNotificationMutation__
 *
 * To run a mutation, you first call `useSendShowNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendShowNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendShowNotificationMutation, { data, loading, error }] = useSendShowNotificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendShowNotificationMutation(baseOptions?: Apollo.MutationHookOptions<SendShowNotificationMutation, SendShowNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendShowNotificationMutation, SendShowNotificationMutationVariables>(SendShowNotificationDocument, options);
      }
export type SendShowNotificationMutationHookResult = ReturnType<typeof useSendShowNotificationMutation>;
export type SendShowNotificationMutationResult = Apollo.MutationResult<SendShowNotificationMutation>;
export type SendShowNotificationMutationOptions = Apollo.BaseMutationOptions<SendShowNotificationMutation, SendShowNotificationMutationVariables>;