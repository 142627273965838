import * as Types from '../../../../network/graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DispatchShipmentByOwnMethodsMutationVariables = Types.Exact<{
  input: Types.DispatchShipmentByOwnMethodsInput;
}>;


export type DispatchShipmentByOwnMethodsMutation = { __typename?: 'Mutation', dispatchShipmentByOwnMethods: { __typename?: 'DispatchShipmentByOwnMethodsPayload', shipment: { __typename?: 'Shipment', id: string } } };

export type UpdateShipmentShippingInfoByOwnMethodsMutationVariables = Types.Exact<{
  input: Types.UpdateShipmentShippingInfoByOwnMethodsInput;
}>;


export type UpdateShipmentShippingInfoByOwnMethodsMutation = { __typename?: 'Mutation', updateShipmentShippingInfoByOwnMethods: { __typename?: 'UpdateShipmentShippingInfoByOwnMethodsPayload', shipment: { __typename?: 'Shipment', id: string } } };

export type QuoteBoxtalFieldsFragment = { __typename?: 'BoxtalShippingMethod', characteristics?: Array<string | null> | null, collectionType?: string | null, currency?: string | null, deliveryType?: string | null, insurancePrice?: string | null, logoUrl?: string | null, operatorCode?: string | null, price?: string | null, service?: string | null, serviceCode?: string | null, shippingOfferCode: string, shippingProvider?: string | null };

export type QuoteForBoxtalMutationVariables = Types.Exact<{
  input: Types.QuoteForBoxtalInput;
}>;


export type QuoteForBoxtalMutation = { __typename?: 'Mutation', quoteForBoxtal: { __typename?: 'QuoteForBoxtalPayload', quotes: Array<{ __typename?: 'BoxtalShippingMethod', characteristics?: Array<string | null> | null, collectionType?: string | null, currency?: string | null, deliveryType?: string | null, insurancePrice?: string | null, logoUrl?: string | null, operatorCode?: string | null, price?: string | null, service?: string | null, serviceCode?: string | null, shippingOfferCode: string, shippingProvider?: string | null }> } };

export type DispatchShipmentWithBoxtalMutationVariables = Types.Exact<{
  input: Types.DispatchShipmentWithBoxtalInput;
}>;


export type DispatchShipmentWithBoxtalMutation = { __typename?: 'Mutation', dispatchShipmentWithBoxtal: { __typename?: 'DispatchShipmentWithBoxtalPayload', shipment: { __typename?: 'Shipment', id: string } } };

export type QuoteSendcloudFieldsFragment = { __typename?: 'SendcloudShippingMethod', carrier: string, firstMile?: string | null, id: number, lastMile?: string | null, name: string, servicePointId?: number | null, price?: number | null };

export type QuoteForSendcloudMutationVariables = Types.Exact<{
  input: Types.QuoteForSencloudInput;
}>;


export type QuoteForSendcloudMutation = { __typename?: 'Mutation', quoteForSendcloud: { __typename?: 'QuoteForSendcloudPayload', quotes: Array<{ __typename?: 'SendcloudShippingMethod', carrier: string, firstMile?: string | null, id: number, lastMile?: string | null, name: string, servicePointId?: number | null, price?: number | null }> } };

export type DispatchShipmentWithSendcloudMutationVariables = Types.Exact<{
  input: Types.DispatchShipmentWithSendcloudInput;
}>;


export type DispatchShipmentWithSendcloudMutation = { __typename?: 'Mutation', dispatchShipmentWithSendcloud: { __typename?: 'DispatchShipmentWithSendcloudPayload', shipment: { __typename?: 'Shipment', id: string } } };

export type DownloadShippingLabelLinkForSendcloudQueryVariables = Types.Exact<{
  nodeId: Types.Scalars['ID']['input'];
}>;


export type DownloadShippingLabelLinkForSendcloudQuery = { __typename?: 'Query', node: { __typename?: 'AutoFollowUserCampaign' } | { __typename?: 'AutocompleteSearchItem' } | { __typename?: 'Badge' } | { __typename?: 'Category' } | { __typename?: 'CommercialEvent' } | { __typename?: 'Country' } | { __typename?: 'Emote' } | { __typename?: 'FavoriteProduct' } | { __typename?: 'FavoriteShow' } | { __typename?: 'ImageUpload' } | { __typename?: 'InventoryProduct' } | { __typename?: 'Language' } | { __typename?: 'LoyaltyLevel' } | { __typename?: 'Order' } | { __typename?: 'OrderedProduct' } | { __typename?: 'ParentCategory' } | { __typename?: 'Product' } | { __typename?: 'ProductForOrdering' } | { __typename?: 'Promotion' } | { __typename?: 'PromotionV2' } | { __typename?: 'RatingFromCustomer' } | { __typename?: 'SellerLedgerPayout' } | { __typename?: 'SellerLedgerTransaction' } | { __typename?: 'SellerShowsAggregatedByMonth' } | { __typename?: 'SellerTier' } | { __typename?: 'Shipment', downloadLinkLabelPrintUrl?: string | null } | { __typename?: 'Shop' } | { __typename?: 'Show' } | { __typename?: 'ShowOrdersGroup' } | { __typename?: 'ShowProductNode' } | { __typename?: 'User' } };

export const QuoteBoxtalFieldsFragmentDoc = gql`
    fragment QuoteBoxtalFields on BoxtalShippingMethod {
  characteristics
  collectionType
  currency
  deliveryType
  insurancePrice
  logoUrl
  operatorCode
  price
  service
  serviceCode
  shippingOfferCode
  shippingProvider
}
    `;
export const QuoteSendcloudFieldsFragmentDoc = gql`
    fragment QuoteSendcloudFields on SendcloudShippingMethod {
  carrier
  firstMile
  id
  lastMile
  name
  price: priceV2
  servicePointId
}
    `;
export const DispatchShipmentByOwnMethodsDocument = gql`
    mutation DispatchShipmentByOwnMethods($input: DispatchShipmentByOwnMethodsInput!) {
  dispatchShipmentByOwnMethods(input: $input) {
    shipment {
      id
    }
  }
}
    `;
export type DispatchShipmentByOwnMethodsMutationFn = Apollo.MutationFunction<DispatchShipmentByOwnMethodsMutation, DispatchShipmentByOwnMethodsMutationVariables>;

/**
 * __useDispatchShipmentByOwnMethodsMutation__
 *
 * To run a mutation, you first call `useDispatchShipmentByOwnMethodsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDispatchShipmentByOwnMethodsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dispatchShipmentByOwnMethodsMutation, { data, loading, error }] = useDispatchShipmentByOwnMethodsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDispatchShipmentByOwnMethodsMutation(baseOptions?: Apollo.MutationHookOptions<DispatchShipmentByOwnMethodsMutation, DispatchShipmentByOwnMethodsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DispatchShipmentByOwnMethodsMutation, DispatchShipmentByOwnMethodsMutationVariables>(DispatchShipmentByOwnMethodsDocument, options);
      }
export type DispatchShipmentByOwnMethodsMutationHookResult = ReturnType<typeof useDispatchShipmentByOwnMethodsMutation>;
export type DispatchShipmentByOwnMethodsMutationResult = Apollo.MutationResult<DispatchShipmentByOwnMethodsMutation>;
export type DispatchShipmentByOwnMethodsMutationOptions = Apollo.BaseMutationOptions<DispatchShipmentByOwnMethodsMutation, DispatchShipmentByOwnMethodsMutationVariables>;
export const UpdateShipmentShippingInfoByOwnMethodsDocument = gql`
    mutation UpdateShipmentShippingInfoByOwnMethods($input: UpdateShipmentShippingInfoByOwnMethodsInput!) {
  updateShipmentShippingInfoByOwnMethods(input: $input) {
    shipment {
      id
    }
  }
}
    `;
export type UpdateShipmentShippingInfoByOwnMethodsMutationFn = Apollo.MutationFunction<UpdateShipmentShippingInfoByOwnMethodsMutation, UpdateShipmentShippingInfoByOwnMethodsMutationVariables>;

/**
 * __useUpdateShipmentShippingInfoByOwnMethodsMutation__
 *
 * To run a mutation, you first call `useUpdateShipmentShippingInfoByOwnMethodsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShipmentShippingInfoByOwnMethodsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShipmentShippingInfoByOwnMethodsMutation, { data, loading, error }] = useUpdateShipmentShippingInfoByOwnMethodsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateShipmentShippingInfoByOwnMethodsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateShipmentShippingInfoByOwnMethodsMutation, UpdateShipmentShippingInfoByOwnMethodsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateShipmentShippingInfoByOwnMethodsMutation, UpdateShipmentShippingInfoByOwnMethodsMutationVariables>(UpdateShipmentShippingInfoByOwnMethodsDocument, options);
      }
export type UpdateShipmentShippingInfoByOwnMethodsMutationHookResult = ReturnType<typeof useUpdateShipmentShippingInfoByOwnMethodsMutation>;
export type UpdateShipmentShippingInfoByOwnMethodsMutationResult = Apollo.MutationResult<UpdateShipmentShippingInfoByOwnMethodsMutation>;
export type UpdateShipmentShippingInfoByOwnMethodsMutationOptions = Apollo.BaseMutationOptions<UpdateShipmentShippingInfoByOwnMethodsMutation, UpdateShipmentShippingInfoByOwnMethodsMutationVariables>;
export const QuoteForBoxtalDocument = gql`
    mutation QuoteForBoxtal($input: QuoteForBoxtalInput!) {
  quoteForBoxtal(input: $input) {
    quotes {
      ...QuoteBoxtalFields
    }
  }
}
    ${QuoteBoxtalFieldsFragmentDoc}`;
export type QuoteForBoxtalMutationFn = Apollo.MutationFunction<QuoteForBoxtalMutation, QuoteForBoxtalMutationVariables>;

/**
 * __useQuoteForBoxtalMutation__
 *
 * To run a mutation, you first call `useQuoteForBoxtalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQuoteForBoxtalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [quoteForBoxtalMutation, { data, loading, error }] = useQuoteForBoxtalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQuoteForBoxtalMutation(baseOptions?: Apollo.MutationHookOptions<QuoteForBoxtalMutation, QuoteForBoxtalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<QuoteForBoxtalMutation, QuoteForBoxtalMutationVariables>(QuoteForBoxtalDocument, options);
      }
export type QuoteForBoxtalMutationHookResult = ReturnType<typeof useQuoteForBoxtalMutation>;
export type QuoteForBoxtalMutationResult = Apollo.MutationResult<QuoteForBoxtalMutation>;
export type QuoteForBoxtalMutationOptions = Apollo.BaseMutationOptions<QuoteForBoxtalMutation, QuoteForBoxtalMutationVariables>;
export const DispatchShipmentWithBoxtalDocument = gql`
    mutation DispatchShipmentWithBoxtal($input: DispatchShipmentWithBoxtalInput!) {
  dispatchShipmentWithBoxtal(input: $input) {
    shipment {
      id
    }
  }
}
    `;
export type DispatchShipmentWithBoxtalMutationFn = Apollo.MutationFunction<DispatchShipmentWithBoxtalMutation, DispatchShipmentWithBoxtalMutationVariables>;

/**
 * __useDispatchShipmentWithBoxtalMutation__
 *
 * To run a mutation, you first call `useDispatchShipmentWithBoxtalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDispatchShipmentWithBoxtalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dispatchShipmentWithBoxtalMutation, { data, loading, error }] = useDispatchShipmentWithBoxtalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDispatchShipmentWithBoxtalMutation(baseOptions?: Apollo.MutationHookOptions<DispatchShipmentWithBoxtalMutation, DispatchShipmentWithBoxtalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DispatchShipmentWithBoxtalMutation, DispatchShipmentWithBoxtalMutationVariables>(DispatchShipmentWithBoxtalDocument, options);
      }
export type DispatchShipmentWithBoxtalMutationHookResult = ReturnType<typeof useDispatchShipmentWithBoxtalMutation>;
export type DispatchShipmentWithBoxtalMutationResult = Apollo.MutationResult<DispatchShipmentWithBoxtalMutation>;
export type DispatchShipmentWithBoxtalMutationOptions = Apollo.BaseMutationOptions<DispatchShipmentWithBoxtalMutation, DispatchShipmentWithBoxtalMutationVariables>;
export const QuoteForSendcloudDocument = gql`
    mutation QuoteForSendcloud($input: QuoteForSencloudInput!) {
  quoteForSendcloud(input: $input) {
    quotes {
      ...QuoteSendcloudFields
    }
  }
}
    ${QuoteSendcloudFieldsFragmentDoc}`;
export type QuoteForSendcloudMutationFn = Apollo.MutationFunction<QuoteForSendcloudMutation, QuoteForSendcloudMutationVariables>;

/**
 * __useQuoteForSendcloudMutation__
 *
 * To run a mutation, you first call `useQuoteForSendcloudMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQuoteForSendcloudMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [quoteForSendcloudMutation, { data, loading, error }] = useQuoteForSendcloudMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQuoteForSendcloudMutation(baseOptions?: Apollo.MutationHookOptions<QuoteForSendcloudMutation, QuoteForSendcloudMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<QuoteForSendcloudMutation, QuoteForSendcloudMutationVariables>(QuoteForSendcloudDocument, options);
      }
export type QuoteForSendcloudMutationHookResult = ReturnType<typeof useQuoteForSendcloudMutation>;
export type QuoteForSendcloudMutationResult = Apollo.MutationResult<QuoteForSendcloudMutation>;
export type QuoteForSendcloudMutationOptions = Apollo.BaseMutationOptions<QuoteForSendcloudMutation, QuoteForSendcloudMutationVariables>;
export const DispatchShipmentWithSendcloudDocument = gql`
    mutation DispatchShipmentWithSendcloud($input: DispatchShipmentWithSendcloudInput!) {
  dispatchShipmentWithSendcloud(input: $input) {
    shipment {
      id
    }
  }
}
    `;
export type DispatchShipmentWithSendcloudMutationFn = Apollo.MutationFunction<DispatchShipmentWithSendcloudMutation, DispatchShipmentWithSendcloudMutationVariables>;

/**
 * __useDispatchShipmentWithSendcloudMutation__
 *
 * To run a mutation, you first call `useDispatchShipmentWithSendcloudMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDispatchShipmentWithSendcloudMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dispatchShipmentWithSendcloudMutation, { data, loading, error }] = useDispatchShipmentWithSendcloudMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDispatchShipmentWithSendcloudMutation(baseOptions?: Apollo.MutationHookOptions<DispatchShipmentWithSendcloudMutation, DispatchShipmentWithSendcloudMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DispatchShipmentWithSendcloudMutation, DispatchShipmentWithSendcloudMutationVariables>(DispatchShipmentWithSendcloudDocument, options);
      }
export type DispatchShipmentWithSendcloudMutationHookResult = ReturnType<typeof useDispatchShipmentWithSendcloudMutation>;
export type DispatchShipmentWithSendcloudMutationResult = Apollo.MutationResult<DispatchShipmentWithSendcloudMutation>;
export type DispatchShipmentWithSendcloudMutationOptions = Apollo.BaseMutationOptions<DispatchShipmentWithSendcloudMutation, DispatchShipmentWithSendcloudMutationVariables>;
export const DownloadShippingLabelLinkForSendcloudDocument = gql`
    query DownloadShippingLabelLinkForSendcloud($nodeId: ID!) {
  node(id: $nodeId) {
    ... on Shipment {
      downloadLinkLabelPrintUrl
    }
  }
}
    `;

/**
 * __useDownloadShippingLabelLinkForSendcloudQuery__
 *
 * To run a query within a React component, call `useDownloadShippingLabelLinkForSendcloudQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadShippingLabelLinkForSendcloudQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadShippingLabelLinkForSendcloudQuery({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useDownloadShippingLabelLinkForSendcloudQuery(baseOptions: Apollo.QueryHookOptions<DownloadShippingLabelLinkForSendcloudQuery, DownloadShippingLabelLinkForSendcloudQueryVariables> & ({ variables: DownloadShippingLabelLinkForSendcloudQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DownloadShippingLabelLinkForSendcloudQuery, DownloadShippingLabelLinkForSendcloudQueryVariables>(DownloadShippingLabelLinkForSendcloudDocument, options);
      }
export function useDownloadShippingLabelLinkForSendcloudLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DownloadShippingLabelLinkForSendcloudQuery, DownloadShippingLabelLinkForSendcloudQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DownloadShippingLabelLinkForSendcloudQuery, DownloadShippingLabelLinkForSendcloudQueryVariables>(DownloadShippingLabelLinkForSendcloudDocument, options);
        }
export function useDownloadShippingLabelLinkForSendcloudSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DownloadShippingLabelLinkForSendcloudQuery, DownloadShippingLabelLinkForSendcloudQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DownloadShippingLabelLinkForSendcloudQuery, DownloadShippingLabelLinkForSendcloudQueryVariables>(DownloadShippingLabelLinkForSendcloudDocument, options);
        }
export type DownloadShippingLabelLinkForSendcloudQueryHookResult = ReturnType<typeof useDownloadShippingLabelLinkForSendcloudQuery>;
export type DownloadShippingLabelLinkForSendcloudLazyQueryHookResult = ReturnType<typeof useDownloadShippingLabelLinkForSendcloudLazyQuery>;
export type DownloadShippingLabelLinkForSendcloudSuspenseQueryHookResult = ReturnType<typeof useDownloadShippingLabelLinkForSendcloudSuspenseQuery>;
export type DownloadShippingLabelLinkForSendcloudQueryResult = Apollo.QueryResult<DownloadShippingLabelLinkForSendcloudQuery, DownloadShippingLabelLinkForSendcloudQueryVariables>;