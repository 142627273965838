import { useTranslation } from 'react-i18next'

import Button from '@/components/ui/Button/Button'
import EventIcon from '@/components/ui/Icons/EventIcon/EventIcon'

import './ApplyForShowEvent.scss'

type ApplyForShowEventProps = {
  handleOnClick: () => void
}

const ApplyForShowEvent = ({ handleOnClick }: ApplyForShowEventProps) => {
  const { t } = useTranslation()

  return (
    <Button
      className="apply-for-show-event-action"
      icon={<EventIcon />}
      label={t('showEventTypeformLinkText')}
      tooltip={`${t('showEventTypeformLinkText')} ${t('showEventTypeformLinkPopover')}`}
      tooltipPosition="bottom right"
      onClick={handleOnClick}
    />
  )
}

export default ApplyForShowEvent
