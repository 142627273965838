import { Form, Input, Image, Select, Upload, message, Switch, Checkbox } from 'antd'
import moment from 'moment'
import { useState, useEffect, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { FaCircleDot } from 'react-icons/fa6'
import { connect } from 'react-redux'

import { VideoUploaderContainer } from '@/components/CreateShow/components/VideoUploader/VideoUploader.container'
import { DEFAULT_COUNTRY_ID } from '@/config/config'
import { useUser } from '@/contexts/user/User.context'
import getShowShippingCategories from '@/data/showShippingCategories'
import { legacyCurrencyFormatter } from '@/helpers/currencyFormatter'
import { getFromLocalStorage, setToLocalStorage } from '@/helpers/localstorage'
import { mappingToShippingCategory } from '@/modules/shipping-category/mapping-shipping-category'
import { StreamingType } from '@/network/graphql/types.generated'
import { trackEvent } from '@/util/eventTracker'

import InputLabel from '../../InputLabel'
import {
  useDeletePreShowTeaserVideoMutation,
  useGetAllLanguagesQuery,
  useGetDefaultShowLanguageQuery,
} from '../operations.generated'

import type { OnSubmitCreateShowInput, OnSubmitUpdateShowInput, PreShowTeaserVideoInput } from '../types'
import type { VideoUploadedStatus } from '@/components/CreateShow/components/VideoUploader/VideoUploader.container'
import type { CustomUrlPreshow, ShowShippingOption } from '@/types'
import type { UploadChangeParam, UploadFile } from 'antd/es/upload/interface'
import type { Moment } from 'moment'

import style from './style.module.scss'
import globalStyle from '../../../styles/_export.module.scss'

const MAX_IMAGE_SIZE = 10000000
const MIN_GMV_TARGET_AMOUNT = 1
const MAX_GMV_TARGET_AMOUNT = 50000

interface ShowDetailsFormFieldsProps {
  show: any
  categoryId: number
  date: Moment | null
  time: any // TODO: fix type
  setAllFormComplete: (value: boolean) => void
  onSubmitCreateShow: (
    input: OnSubmitCreateShowInput,
    thumbnail?: UploadFile<any>,
    teaserVideo?: PreShowTeaserVideoInput
  ) => void
  onSubmitUpdateShow: (
    input: OnSubmitUpdateShowInput,
    thumbnail?: UploadFile<any>,
    teaserVideo?: PreShowTeaserVideoInput
  ) => void
  isOnlyCategoryAndDate?: boolean | null
  sellerCountry: any
  customUrlPreShow: CustomUrlPreshow
  setVideoUploadStatus: (value: VideoUploadedStatus) => void
  videoUploadStatus: VideoUploadedStatus
  createShop?: boolean
}

const OPTIMAL_PICTURE_RATIO_MIN = 1.1
const OPTIMAL_PICTURE_RATIO_MAX = 1.3

const isGMVTargetAmountValid = (value: number) => {
  return value >= MIN_GMV_TARGET_AMOUNT && value <= MAX_GMV_TARGET_AMOUNT
}

// TODO: fix type
const computeStartAtDate = (date: Moment | null, time: any) => {
  // TODO: Use day.js instead of moment
  const startAt = moment(
    `${moment(date).format('YYYY-MM-DD')} ${moment(time).format('HH:mm:ss')}`,
    moment.defaultFormat
  ).toDate()
  return startAt
}

const ShowDetailsFormFields = (props: ShowDetailsFormFieldsProps) => {
  const {
    show,
    categoryId,
    date,
    time,
    setAllFormComplete,
    onSubmitCreateShow,
    onSubmitUpdateShow,
    isOnlyCategoryAndDate = false,
    sellerCountry,
    customUrlPreShow,
    setVideoUploadStatus,
    videoUploadStatus,
    createShop,
    // onVideoChange,
  } = props

  const { t } = useTranslation()
  const isMounted = useRef(false)
  const [form] = Form.useForm()

  const { user } = useUser()
  const { sellerConfig } = user || {}
  const { canAccessPreAuth } = sellerConfig || {}

  const { Option } = Select
  const defaultLabelValue = [{ label: '', value: '' }]

  const showGlobalId = `Show|${show?.id}`
  const countryValue = show?.countryId || sellerCountry
  const shippingOptions = getShowShippingCategories(countryValue, categoryId)
  const showCategoryOption =
    show && shippingOptions.find((shippingOption) => shippingOption.value === show.shippingCategory)

  const storedShippingOption = getFromLocalStorage('lastShowShippingCategorySelected')
  const defaultShippingOption = shippingOptions.find((shippingOption) => shippingOption.value === storedShippingOption)
    ? storedShippingOption
    : undefined
  const [thumbnail, setThumbnail] = useState<UploadFile>()
  const [note, setNote] = useState<string>(show ? show.note : '')
  const [name, setName] = useState<string>(show ? show.name : '')
  const [languageId, setLanguageId] = useState<string>('')
  const [languagesForSelect, setLanguagesForSelect] = useState(defaultLabelValue)
  const [preAuthAmount, setPreAuthAmount] = useState<number>(show ? show.paymentPreAuthorizationAmount / 100 : 0)
  const [isPreAuthEnabled, setIsPreAuthEnabled] = useState<boolean>(
    show ? show.paymentPreAuthorizationAmount > 0 : false
  )
  const [isReusingPaidShippingFeesFromPreviousShows, setIsReusingPaidShippingFeesFromPreviousShows] = useState<boolean>(
    show ? show.isReusingPaidShippingFeesFromPreviousShows : false
  )
  const [shippingCategory, setShippingCategory] = useState<ShowShippingOption>(
    show ? showCategoryOption?.value : defaultShippingOption
  )
  const [gmvTargetAmount, setGmvTargetAmount] = useState<number>(show?.gmvTargetAmount / 100 || MIN_GMV_TARGET_AMOUNT)
  const [hasShowPictureOptimalRatio, setShowPictureOptimalRatio] = useState<boolean>(false)
  const [videoName, setVideoName] = useState<string>('')
  const [videoSize, setVideoSize] = useState<number>(0)

  const { data: languages } = useGetAllLanguagesQuery()
  const { data: languageDefault, loading: loadingLanguages } = useGetDefaultShowLanguageQuery()
  const [deletePreShowTeaserVideo] = useDeletePreShowTeaserVideoMutation()

  const selectedShippingOption = shippingOptions.find((shippingOption) => shippingOption.value === shippingCategory)

  // TODO: merge handleCreate and handleUpdate?
  const handleCreate = async () => {
    const input = {
      name,
      note,
      categoryId,
      shippingCategory: mappingToShippingCategory[shippingCategory],
      startAt: computeStartAtDate(date, time),
      streamingType: StreamingType.Rtmp,
      paymentPreAuthorizationAmount: preAuthAmount ? preAuthAmount * 100 : 0,
      gmvTargetAmount: gmvTargetAmount ? gmvTargetAmount * 100 : 0,
      isReusingPaidShippingFeesFromPreviousShows,
      languageId: Number(languageId),
    }

    const teaserVideo = videoName
      ? {
          originalFilename: videoName,
          originalFilesize: videoSize,
        }
      : undefined

    onSubmitCreateShow(input, thumbnail?.originFileObj, teaserVideo)
  }

  const handleUpdate = async () => {
    if (!show || !isMounted.current) {
      return
    }

    const input = {
      name,
      note,
      categoryId,
      shippingCategory: shippingCategory,
      startAt: computeStartAtDate(date, time),
      paymentPreAuthorizationAmount: preAuthAmount ? preAuthAmount * 100 : 0,
      gmvTargetAmount: gmvTargetAmount ? gmvTargetAmount * 100 : 0,
      isReusingPaidShippingFeesFromPreviousShows,
      languageId: Number(languageId),
    }

    const teaserVideo = videoName
      ? {
          originalFilename: videoName,
          originalFilesize: videoSize,
        }
      : undefined

    onSubmitUpdateShow(input, thumbnail?.originFileObj, teaserVideo)
  }

  const handleDeletePreShowTeaserVideo = async () => {
    await deletePreShowTeaserVideo({
      variables: {
        input: {
          showId: showGlobalId,
        },
      },
    })
  }

  const onFileUpload = (info: UploadChangeParam<UploadFile<any>>) => {
    try {
      setShowPictureOptimalRatioForImage(info.file.originFileObj as Blob)
    } catch (_) {
      setShowPictureOptimalRatio(false)
    }
  }

  const setShowPictureOptimalRatioForImage = (file: Blob) => {
    const reader = new FileReader()

    reader.readAsDataURL(file)

    reader.addEventListener('load', (event) => {
      const _loadedImageUrl = event.target?.result

      setShowPictureOptimalRatioForUrl(_loadedImageUrl as string)
    })
  }

  const setShowPictureOptimalRatioForUrl = (url: string) => {
    if (isMounted?.current) {
      const image = document.createElement('img')

      image.src = url

      image.addEventListener('load', () => {
        const { width, height } = image
        const ratio = height / width

        const isRatioOK = ratio > OPTIMAL_PICTURE_RATIO_MIN && ratio < OPTIMAL_PICTURE_RATIO_MAX

        setShowPictureOptimalRatio(isRatioOK)
      })
    }
  }

  const onPreAuthChange = (checked: boolean) => {
    setIsPreAuthEnabled(checked)
    if (checked && show?.paymentPreAuthorizationAmount > 0) setPreAuthAmount(show.paymentPreAuthorizationAmount)

    if (!checked) setPreAuthAmount(0)
  }

  const onVideoChangeDuringUpdate = (info: any) => {
    const { name, size } = info.file

    setVideoName(name)
    setVideoSize(size)
  }

  const handleGmvTargetAmountChange = (e: any) => {
    const {
      target: { value },
    } = e
    const parsedValue = parseInt(value, 10)
    setGmvTargetAmount(parsedValue)
  }

  // TODO: improve type
  const handleThumbnailChange = (e: any) => {
    const { fileList, file } = e || {}

    if (fileList?.length === 0) {
      setThumbnail(undefined)
      return
    }

    if (file?.originFileObj) {
      const { originFileObj } = file

      if (originFileObj.size > MAX_IMAGE_SIZE) {
        setThumbnail(undefined)
        return
      }
      setThumbnail(file)
    }
  }

  const handleSelectShippingCategory = (value: ShowShippingOption) => {
    setShippingCategory(value)
    setToLocalStorage('lastShowShippingCategorySelected', value)
  }

  useEffect(() => {
    if (languages?.allLanguages && languages.allLanguages.length > 0) {
      setLanguagesForSelect(
        languages.allLanguages.map((language) => {
          return {
            label: language.code,
            value: language.id.split('|')[1],
          }
        })
      )
    }
  }, [languages])

  useEffect(() => {
    if (loadingLanguages || languageId) return
    if (!languageDefault?.viewer?.sellerConfig?.defaultShowLanguage?.id) return
    const language = languageDefault.viewer.sellerConfig.defaultShowLanguage.id.split('|')[1].toString()
    setLanguageId(language)
    if (!show?.languageId) {
      form.setFieldValue('language', language)
    } else {
      form.setFieldValue('language', show.languageId.toString())
    }
  }, [languageDefault])

  useEffect(() => {
    if (isOnlyCategoryAndDate) {
      handleUpdate()
    }
  }, [])

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (isMounted?.current) {
      const isComplete = Boolean(
        name?.length && (thumbnail !== undefined || show?.thumbnailUrl) && isGMVTargetAmountValid(gmvTargetAmount)
      )
      setAllFormComplete(isComplete)
    }
  }, [note, name, thumbnail, setAllFormComplete, show, isMounted, gmvTargetAmount])

  useEffect(() => {
    if (show && isMounted) trackEvent('SHOW_UPDATE', { showId: show.id })
    else trackEvent('SHOW_CREATE')
  }, [show])

  useEffect(() => {
    if (!show || !isMounted?.current) return

    form.setFieldsValue({
      shippingCategory, // TODO: is this really necessary?
      categoryId, // TODO: is this really necessary?
      name,
    })

    if (show.thumbnailUrl) {
      if (!hasShowPictureOptimalRatio) setShowPictureOptimalRatioForUrl(show.thumbnailUrl)
    }
  }, [show, form, categoryId, shippingCategory, name])

  return (
    <Form
      className={style.form__container}
      form={form}
      id="myForm"
      initialValues={{ remember: true }}
      layout="vertical"
      name="basic"
      onFinish={show ? handleUpdate : handleCreate}
    >
      <InputLabel
        title={createShop ? t('createShopModalDetailsFormShowName') : t('createShowModalDetailsFormShowName')}
        mandatory
      />
      <Form.Item
        initialValue={name}
        name="name"
        rules={[
          {
            required: true,
            message: t('createShowModalDetailsFormFieldRequired'),
          },
        ]}
      >
        <Input onChange={({ target: { value } }) => setName(value)} />
      </Form.Item>

      <InputLabel title={createShop ? t('createShopModalDetailsFormNote') : t('createShowModalDetailsFormNote')} />
      <Form.Item
        initialValue={note}
        name="note"
        extra={
          <div className={style.form__note_extra}>
            <Trans
              i18nKey={createShop ? 'createShopModalDetailsNoteInfo' : 'createShowModalDetailsNoteInfo'}
              components={{
                s: <span />,
              }}
            />
          </div>
        }
      >
        <Input.TextArea className={style.input} onChange={({ target: { value } }) => setNote(value)} />
      </Form.Item>

      {!show && !createShop && (
        <>
          <InputLabel title={t('createShowModalDetailsFormLanguage')} />

          <Form.Item
            name="language"
            extra={
              <div className={style.form__note_extra}>
                <Trans
                  i18nKey="createShowModalDetailsLangueInfo"
                  components={{
                    s: <span />,
                  }}
                />
              </div>
            }
          >
            <Select
              className={style.form__select}
              options={languagesForSelect}
              value={languageId}
              onChange={(value) => setLanguageId(value)}
            />
          </Form.Item>
        </>
      )}

      <InputLabel title={t('createShowModalDetailsFormShippingType')} mandatory />
      <Form.Item
        initialValue={show?.shippingCategory || defaultShippingOption}
        name="shippingCategory"
        style={{ marginBottom: 0 }}
        extra={
          shippingCategory &&
          selectedShippingOption?.amount && (
            <div className={style.form__select_amount}>
              <Trans
                i18nKey="createShowModalDetailsFormShippingTypeNotice"
                components={{
                  s: <span />,
                }}
              />
            </div>
          )
        }
        rules={[
          {
            required: true,
            message: t('createShowModalDetailsFormFieldRequired'),
          },
        ]}
      >
        <Select className={style.form__select} value={shippingCategory} onChange={handleSelectShippingCategory}>
          {shippingOptions.map((shippingOption, i) => {
            return (
              <Option key={`shipping_option_${i}`} value={shippingOption.value}>
                {t(shippingOption.label)} ({legacyCurrencyFormatter(shippingOption.amount || 0)})
              </Option>
            )
          })}
        </Select>
      </Form.Item>

      <Form.Item>
        <Checkbox
          checked={isReusingPaidShippingFeesFromPreviousShows}
          style={{ marginTop: -4, marginBottom: 2 }}
          onChange={() => setIsReusingPaidShippingFeesFromPreviousShows((b) => !b)}
        >
          {t('createShowModalDetailsFormReusePaidShippingFeesFromPreviousShows')}
        </Checkbox>
      </Form.Item>

      <Form.Item
        rules={[
          {
            required: true,
            message: t('createShowModalDetailsFormFieldRequired'),
          },
        ]}
      >
        <InputLabel
          title={t('createShowModalDetailsFormCoverInputLabel')}
          noticePopover={{
            title: (
              <span className="callout_1">
                {t('createShowModalThumbnailNoticeTitle1')}{' '}
                <span
                  className={`${style.thumbnail__notice_title} callout_6`}
                  onClick={() => window.open(t('supportLinkArticleShowThumbnailTemplate'))}
                >
                  {t('createShowModalThumbnailNoticeTitle2')}
                </span>
              </span>
            ),
            content: t('createShowModalDetailsFormAddCoverAspectRatioInfo'),
          }}
          mandatory
        />
        <Form.Item getValueFromEvent={handleThumbnailChange} name="dragger" valuePropName="fileList" noStyle>
          <Upload.Dragger
            accept=".png, .jpg, .jpeg, .heic, .heif"
            fileList={thumbnail ? [thumbnail] : []}
            maxCount={1}
            name="files"
            beforeUpload={(file) => {
              if (file.size > MAX_IMAGE_SIZE) {
                message.error(t('createShowModalDetailsFormAddCoverWeightError'))
              }
            }}
            customRequest={(e) => {
              if (thumbnail) return e.onSuccess && e.onSuccess('ok')
              else e.onError && e.onError({ name: 'ERROR_UPLOAD', message: 'Error when uploading file' })
            }}
            onChange={onFileUpload}
          >
            {show?.thumbnailUrl ? (
              <div>
                <h4>{t('createShowModalDetailsFormActualCoverTitle')}</h4>
                <Image
                  height={300}
                  preview={false}
                  src={thumbnail?.originFileObj ? URL.createObjectURL(thumbnail.originFileObj) : show.thumbnailUrl}
                />
              </div>
            ) : (
              <>
                {thumbnail?.originFileObj ? (
                  <div>
                    <h4>{t('createShowModalDetailsFormActualCoverTitle')}</h4>
                    <Image height={300} preview={false} src={URL.createObjectURL(thumbnail.originFileObj)} />
                  </div>
                ) : (
                  <>
                    <h4>
                      {`${
                        show?.thumbnailUrl
                          ? t('createShowModalDetailsFormUpdateCoverLabel')
                          : t('createShowModalDetailsFormAddCoverLabel')
                      }`}
                    </h4>
                    <p>{t('createShowModalDetailsFormAddCoverWeightInfo')}</p>
                  </>
                )}
              </>
            )}
          </Upload.Dragger>
        </Form.Item>
      </Form.Item>

      <VideoUploaderContainer
        customUrl={customUrlPreShow}
        label={t('createShowModalDetailsFormUploadVideoInputLabel')}
        preShowTeaserVideoInfo={show?.preShowTeaserVideoInfo}
        setVideoUploadStatus={setVideoUploadStatus}
        videoUploadStatus={videoUploadStatus}
        onDelete={handleDeletePreShowTeaserVideo}
        onVideoChange={onVideoChangeDuringUpdate} // TODO: Use a context to avoid that
      />

      {canAccessPreAuth && (
        <>
          <InputLabel
            title={t('createShowModalDetailsFormPreAuthInputLabel')}
            noticePopover={{
              title: t('createShowModalDetailsFormPreAuthNoticePopoverTitle'),
              content: t('createShowModalDetailsFormPreAuthNoticePopoverContent'),
            }}
          />
          <Form.Item name="preAuth">
            <>
              <Switch checked={isPreAuthEnabled} onChange={onPreAuthChange} />
              {isPreAuthEnabled && (
                <div className={style.pre_auth__input_container}>
                  <span>
                    {t('commonMinimum')}: {legacyCurrencyFormatter(10)}
                  </span>
                  <span>
                    {t('commonMaximum')}: {legacyCurrencyFormatter(100)}
                  </span>
                  <Input
                    max={100}
                    min={10}
                    step="1"
                    type="number"
                    value={preAuthAmount}
                    onChange={({ target: { value } }) => setPreAuthAmount(parseInt(value))}
                    onWheel={(e) => e.currentTarget.blur()}
                  />
                </div>
              )}
            </>
          </Form.Item>
        </>
      )}
      <>
        <div className={style.label__gmv_target}>
          <InputLabel title={t('createShowModalDetailsFormGmvTargetInputLabel')} />
          <span>
            <FaCircleDot color={globalStyle.black120} size={12} />
          </span>
        </div>
        <Form.Item
          initialValue={gmvTargetAmount}
          name="gmvTargetAmount"
          rules={[
            {
              type: 'number',
              min: MIN_GMV_TARGET_AMOUNT,
              max: MAX_GMV_TARGET_AMOUNT,
              transform: (value) => {
                return parseInt(value, 10)
              },
              message: t('showCreationGmvTargetInvalidError', {
                min: MIN_GMV_TARGET_AMOUNT,
                max: MAX_GMV_TARGET_AMOUNT,
              }),
            },
          ]}
        >
          <div className={style.gmv_target__input_container}>
            <span>{t('createShowModalDetailsFormGmvTargetInputDescription')}</span>
            <span>
              {t('commonMinimum')}: {legacyCurrencyFormatter(1)}
            </span>
            <Input
              max={MAX_GMV_TARGET_AMOUNT}
              min={MIN_GMV_TARGET_AMOUNT}
              step="1"
              type="number"
              value={gmvTargetAmount ?? 1}
              onChange={handleGmvTargetAmountChange}
              onWheel={(e) => e.currentTarget.blur()}
            />
          </div>
        </Form.Item>
      </>
    </Form>
  )
}

function mapStateToProps(state: any) {
  return {
    sellerCountry: state.authentication?.user?.sellerConfig?.countryId || DEFAULT_COUNTRY_ID,
  }
}

export default connect(mapStateToProps)(ShowDetailsFormFields)
