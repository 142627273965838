import { DateContainer } from './DateContainer'

type EventHeaderProps = {
  banner?: string
  title: string
  description?: string
  startAt?: Date
  endAt?: Date
}

const EventHeader = ({ banner, title, description, startAt, endAt }: EventHeaderProps) => {
  return (
    <div className="event-header">
      {banner && (
        <div className="event-header__banner">
          <img alt={title} src={banner} />
        </div>
      )}
      <div className="event-header__content">
        <h2 className="event-header__title">{title}</h2>
        {description && <p className="event-header__description">{description}</p>}
        {startAt && <DateContainer endAt={endAt} startAt={startAt} />}
      </div>
    </div>
  )
}

export default EventHeader
